import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import logo from './media/logo.jpg';
import './App.css';

function App() {
  return (
    <div className="App">
      <Grid container direction="row" justify="center" alignItems="stretch">
        <Grid item container direction="column" justify="center" alignItems="stretch">
          <Grid item>
              <img src={logo} alt="puffer-fish-logo" aria-label="Puffer Fish Tiki Logo" className="pufferfish-logo" />
          </Grid>
          <Grid item>
            <Typography variant="h3" className="coming-soon-text">
                Website Coming Soon...
            </Typography>
          </Grid>
        </Grid>
      </Grid>

    </div>
  );
}

export default App;
